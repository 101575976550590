import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { getCookieOne } from "../../utils";

export default ({ component: ComposedComponent, ...rest }) => {
  class Authentication extends Component {
    render() {
      return (
        <Route
          {...rest}
          render={props => {
            let isLogin = getCookieOne('token');
            return isLogin ? (
              <ComposedComponent {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
              />
            )
          }
          }
        />
      );
    }
  }

  return <Authentication />;
};
