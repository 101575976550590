/**
 * 密码强度提示组件
 * */

import React, { Component } from 'react'
import './index.scss'


export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tipsTexts: {
        1: '密码长度6~20位',
        2: '密码必须包含字母',
        3: '大写字母，小写字母，数字和标点符号至少包含2种'
      },
      successTips: [],// 验证通过的提示语
      errorTips: [],// 验证不通过的提示语
      securityLevel: 0,// 密码的安全等级，分为 3 个等级
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  // 密码发生更改，在父组件调用，返回错误提示的第一条，没有错误提示就返回空字符串
  onChange = (value) => {
    let successTips = [];
    let errorTips = [];
    let hasUpperCase = /[A-Z]/.test(value);
    let hasLowerCase = /[a-z]/.test(value);
    let hasNumber = /[0-9]/.test(value);
    let hasOther = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/im.test(value);
    let booleans = [hasUpperCase, hasLowerCase, hasNumber, hasOther]
    // 密码长度验证
    if (value.length < 6 || value.length > 20) {
      errorTips.push(this.state.tipsTexts[1])
    } else {
      successTips.push(this.state.tipsTexts[1])
    }
    // 密码是否包含大小写
    if (!hasUpperCase && !hasLowerCase) {
      errorTips.push(this.state.tipsTexts[2])
    } else {
      successTips.push(this.state.tipsTexts[2])
    }
    // 密码是否至少两种字符组合
    if ((hasUpperCase || hasLowerCase) && (hasNumber || hasOther)) {
      successTips.push(this.state.tipsTexts[3])
    } else {
      errorTips.push(this.state.tipsTexts[3])
    }
    // 密码强度
    let securityLevel = booleans.filter(value => value).length;
    this.setState({
      securityLevel
    })
    this.setState({
      successTips,
      errorTips,
    })
    return errorTips[0] || ''
  }

  render () {
    return (
      <div className={`password-valid-tips`}>
        {
          this.state.errorTips.length > 0 ? (
            <p className="title">密码不符合要求</p>
          ) : (
            <p className="title">
              密码安全程度：
              {
                this.state.securityLevel === 1 && <span className="green">弱</span>
              }
              {
                this.state.securityLevel === 2 && <span className="yellow">中</span>
              }
              {
                this.state.securityLevel === 3 && <span className="red">强</span>
              }
            </p>
          )
        }
        <div className="security-level">
          <div className={`item ${this.state.securityLevel >= 1 ? 'green' : ''}`}/>
          <div className={`item ${this.state.securityLevel >= 2 ? 'yellow' : ''}`}/>
          <div className={`item ${this.state.securityLevel >= 3 ? 'red' : ''}`}/>
        </div>
        <div className="tips-text-wrap">
          {
            this.state.successTips.map((value, index) => {
              return (
                <div className="tips-text success" key={index}>
                  <i className="iconfont">&#xe60f;</i>
                  <p className="text">{value}</p>
                </div>
              )
            })
          }
          {
            this.state.errorTips.map((value, index) => {
              return (
                <div className="tips-text error" key={index}>
                  <i className="iconfont">&#xe65f;</i>
                  <p className="text">{value}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}
