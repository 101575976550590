/**
 * Created by Jachin on 2019/6/21.
 */
import React, { Component } from 'react'
import './index.scss'
import CustomButton from '../../components/CustomButton'


export default class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }
  // 前往完善个人信息
  toEditInfo = () => {
    window.location.hash = '#/supplement-user-info'
  }

  render () {
    return (
      <div className="supplement-user-info-tips">
        <div className="supplement-wrap">
          <img src={require('../../images/supplement-tips.png')} alt="" className="supplement-tips-icon"/>
          <p className="page-title">信息未完善</p>
          <p className="page-desc">您的个人信息未完善，如要继续操作，请先完善个人信息</p>
          <div className="button-wrap">
            <CustomButton onClick={this.toEditInfo}>完善个人信息</CustomButton>
          </div>
        </div>
      </div>
    )
  }
}

