/**
 * Created by Jachin on 2019/4/11.
 */

/**
 * @desc 查询全部cookie
 * @return {Object} cookie 数据组合的对象
 * */
function getCookie () {
  let cookieString = document.cookie;
  let arr = cookieString.split(';');
  let obj = {};
  for(let i = 0; i < arr.length; i++) {
    let a = arr[i].split('=');
    obj[a[0].trim()] = a[1];
  }
  return obj;
}

/**
 * @desc 根据 cookieName 获取 cookie 值
 * @param {String} cookieName cookie 的键
 * @return {?} cookie 的值
 * */
function getCookieOne(cookieName) {
  let cookieString = document.cookie;
  let arr = cookieString.split(';');
  let obj = {};
  for(let i = 0; i < arr.length; i++) {
    let a = arr[i].split('=');
    obj[a[0].trim()] = a[1];
  }
  return obj[cookieName] || '';
}

/**
 * @desc 设置cookie
 * @param {String} cookieName cookie 的键
 * @param {?} ckValue cookie 的值
 * @param {Number} hours 过期时间，单位小时
 * */
function setCookie(ckName, ckValue, hours) {
  // 过期时间
  const expireTime = (new Date()).getTime() + (hours * 3600000);
  // 对象序列化
  if(ckValue instanceof Object) ckValue = JSON.stringify(ckValue);
  // 设置
  document.cookie = ckName + '=' + ckValue + ';expires=' + (new Date(expireTime)).toUTCString();
}

/**
 * @desc 设置localStorage
 * @param {String} key localStorage 的键
 * @param {?} value localStorage 的值
 * */
function setStorage(key, value) {
  localStorage.setItem(key, value);
}

/**
 * @desc 获取localStorage
 * @param {String} key localStorage 的键
 * @return {?} value localStorage 的值
 * */
function getStorage(key) {
  return localStorage.getItem(key);
}

/**
 * @desc 清楚指定localStorage
 * @param {String} key localStorage 的键
 * */
function removeStorage(key) {
  localStorage.removeItem(key);
}

/**
 * @desc 解析url查询参数为对象
 * @param {searchStr} 查询参数(通过location.search获取)
 * @return {object} 返回解析后的对象
 * */
function getQueryParams(searchStr) {
  var queryObj = {};  // 定义一个目标对象
  searchStr = searchStr.slice(1);  // 截取？之后的字符串
  searchStr.split('&').forEach(function(item) {
    var index = item.indexOf('=');
    var key = item.slice(0, index).trim();
    var value = item.slice(index+1).trim();
    queryObj[key] = value;
  });
  return queryObj;
}

/**
 * @desc Base64
 * */
function Base64() {
  // private property
  var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

  // public method for encoding
  this.encode = function(input) {
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;
    input = _utf8_encode(input);
    while(i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
      if(isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if(isNaN(chr3)) {
        enc4 = 64;
      }
      output = output +
        _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
        _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
    }
    return output;
  }

  // public method for decoding
  this.decode = function(input) {
    var output = "";
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    while(i < input.length) {
      enc1 = _keyStr.indexOf(input.charAt(i++));
      enc2 = _keyStr.indexOf(input.charAt(i++));
      enc3 = _keyStr.indexOf(input.charAt(i++));
      enc4 = _keyStr.indexOf(input.charAt(i++));
      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;
      output = output + String.fromCharCode(chr1);
      if(enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }
      if(enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }
    output = _utf8_decode(output);
    return output;
  }

  // private method for UTF-8 encoding
  var _utf8_encode = function(string) {
    string = string.replace(/\r\n/g, "\n");
    var utftext = "";
    for(var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);
      if(c < 128) {
        utftext += String.fromCharCode(c);
      } else if((c > 127) && (c < 2048)) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }

    }
    return utftext;
  }

  // private method for UTF-8 decoding
  var _utf8_decode = function(utftext) {
    var string = "";
    var i = 0;
    var c = 0;
    var c1 = 0;
    var c2 = 0;
    var c3 = 0;
    while(i < utftext.length) {
      c = utftext.charCodeAt(i);
      if(c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if((c > 191) && (c < 224)) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }
    }
    return string;
  }
}

/**
 * @desc   判断是否为手机号
 * @param  {String|Number} str
 * @return {Boolean}
 */
function isPhoneNum(str) {
  return /^[0-9]*$/.test(str)
}

/**
 * @desc   判断是否为邮箱号
 * @param  {String} str
 * @return {Boolean}
 */
function isEmail(str) {
  // return /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(str)
  return /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(str)
}

/**
 * @desc   判断是否为正整数
 * @param  {String|Number} str
 * @return {Boolean}
 */
function isInteger(str) {
  return /^[1-9]+\d*$/.test(str)
}


/**
 * @desc   下载文件
 * @param  {String} path 下载路径
 * @param  {String} fileName 文件名称，默认为路径自带文件名
 */
function downloadFile(path, fileName = '') {
  if (!path) {
    return false
  }
  let aLink = document.createElement("a");
  aLink.href = path;
  aLink.download = fileName; //文件名
  document.body.appendChild(aLink);
  aLink.click(); //点击下载
  document.body.removeChild(aLink); //下载完成移除元素
}


export {
  getCookie,
  getCookieOne,
  setCookie,
  setStorage,
  getStorage,
  removeStorage,
  getQueryParams,
  Base64,
  isPhoneNum,
  isInteger,
  isEmail,
  downloadFile,
};



