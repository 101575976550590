/**
 * Created by Jachin on 2019/4/11.
 */

/**
 * 性别选择组件；
 *
 * */
import React, { Component } from 'react'
import './index.scss'

export default class Index extends Component {
  constructor (props) {
    super(props)
  }

  onClick (type) {
    if(this.props.onChange) {
      this.props.onChange(type)
    }
  }

  render () {
    return (
      <div className="select-gender">
        <p className={`radio-button1 ${this.props.value === 'MALE' ? 'active' : ''}`} onClick={this.onClick.bind(this, 'MALE')}>男</p>
        <p className={`radio-button2 ${this.props.value === 'FEMALE' ? 'active' : ''}`} onClick={this.onClick.bind(this, 'FEMALE')}>女</p>
      </div>
    )
  }
}

