/**
 * Created by Jachin on 2019/4/11.
 */
import React, { Component } from 'react'
import './CustomTabs.scss'


export default class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  handleItem = (index) => {
    if (this.props.onChange) {
      this.props.onChange(index)
    }
  }

  render () {
    return (
      <div className="custom-tabs" style={this.props.style ? this.props.style : {}}>
        <div className="custom-tabs__title">
          {
            React.Children.map(this.props.children, child => {
              return React.cloneElement(child, {
                active: this.props.active,
                onClick: this.handleItem
              })
            })
          }
        </div>
        <div className="custom-tabs__content">
          {
            React.Children.map(this.props.children, child => {
              if (this.props.active === child.props.name && child.props.children) {
                return React.cloneElement(child.props.children)
              }
            })
          }
        </div>
      </div>
    )
  }
}

