/**
 * Created by Jachin on 2019/4/11.
 */

/**
 * tabs.pane
 * @attribute {?} active 当前激活状态的值
 * @attribute {?} name pane对应的值
 * @attribute {String} label pane对应的标题
 * @attribute {Number} count 在标题旁边可以展示数量，非必传
 * @attribute {Number} desc 在标题旁边可以展示描述，非必传
 * */

import React, { Component } from 'react'
import './CustomTabPane.scss'




export default class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  handleItem = (index) => {
    if (this.props.onClick) {
      this.props.onClick(index)
    }
  }

  render () {
    return (
      <div className="custom-tab-pane-wrap">
        <div className={`custom-tab-pane ${this.props.active === this.props.name ? 'active' : ''}`} onClick={this.handleItem.bind(this, this.props.name)}>
          <span className="text">{this.props.label}{this.props.count ? `(${this.props.count})` : ''}</span>
        </div>
        {
          this.props.desc && (
            <div dangerouslySetInnerHTML={{__html: this.props.desc}}/>
          )
        }
      </div>
    )
  }
}

