import React, { Component } from 'react'
import './index.scss'

export default class Index extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    return (
      <div className="wrap user-agreement">
        <div className="user-agreement-title">内推圈用户服务协议</div>
        <p>
          <b>
            特别提示：<br/><br/>
            您在使用内推圈提供的各项服务之前，请您务必审慎阅读、充分理解本协议条款内容。如您不同意本服务协议条款或者不同意内推圈随时对其的修改，您应主动停止使用内推圈提供的服务；您一旦使用内推圈提供的服务，即视为您已理解并完全同意本服务协议各项内容，包括内推圈根据实际情况对本服务协议所做的修改，并成为内推圈的用户。<br/><br/>
            您确认，在您开始注册程序使用内推圈服务前，您应当具备中华人民共和国法律规定的与您的行为相适应的民事行为能力。<br/><br/>
          </b>
        </p>

        <div className="text12b">第一条 总则</div>
        <section>
          <div className="item">
            <p>
              1、成都蓉易聘网络科技有限责任公司（以下简称“内推圈”）通过内推圈网（headhunter.itneituiquan.com）提供服务。为获得内推圈所提供的网络服务，用户应当同意本协议的全部条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击“同意”按钮即表示用户完全接受本协议项下的全部条款。
            </p>
          </div>
          <div className="item">
            <p>
              2、用户注册成功后，其用户帐号和密码由用户负责妥善保管。根据用户性质，分为企业用户和个人用户，经过内推圈的认证，个人用户可以成为猎头。用户应当对以其账户进行的所有活动和事件负法律责任。
            </p>
          </div>
          <div className="item">
            <p>
              3、内推圈有权修改本服务协议并在本网站上予以公布,修改后的服务协议经本网站公布后，立即自动生效；如果用户继续使用本网站，将被视为同意协议修改后的内容。
            </p>
          </div>
        </section>

        <div className="text12b">第二条 用户使用规则</div>
        <section>
          <div className="item">
            <p>
              1、用户应遵守中华人民共和国相关法律法规，包括但不限于《网络安全法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《全国人大常委会关于维护互联网安全的决定》、《互联网电子公告服务管理规定》、《互联网新闻信息服务管理规定》、《互联网著作权行政保护办法》、《信息网络传播权保护条例》等。
            </p>
          </div>
          <div className="item">
            <p>
              2、用户在申请使用内推圈服务时，应向内推圈提供准确的身份资料，如身份资料有任何变动，应该及时、准确更新。如果因注册信息不真实或更新不及时而引发的相关问题，内推圈不负任何责任。用户可以通过内推圈帐号设置页面查询、更正用户信息。
            </p>
          </div>
          <div className="item">
            <p>
              3、用户不应将其帐号、密码转让、出售或出借他人使用。如用户发现其帐号遭他人非法使用，应立即通知内推圈。因用户的保管疏忽等导致帐号、密码遭他人非法使用，内推圈不承担任何责任。
            </p>
          </div>
          <div className="item">
            <p>
              4、用户同意将不会利用内推圈提供的网络存储平台及服务进行任何违法或不正当的活动，用户保证发表、上传或传送的内容合法、真实、有效，并对其内容承担全部责任；同时，用户知晓在内推圈发布的职位信息具有效期，内推圈有权下线有效期届满的职位信息；用户不得在内推圈的任何页面发布、转载、传送含有下列内容之一的信息，否则内推圈有权自行处理并不通知用户：
            </p>
            <p>（1）违反宪法确定的基本原则的；</p>
            <p>（2）危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；</p>
            <p>（3）损害国家荣誉和利益的； </p>
            <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
            <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
            <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
            <p>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
            <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
            <p>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
            <p>（10）以非法民间组织名义活动的；</p>
            <p>（11）性别歧视或指向性歧视，如民族歧视、地域歧视等内容；</p>
            <p>（12）虚假、胁迫、猥亵、骚扰、中伤、粗俗、侵害他人隐私或其他有悖道德、令人反感的内容；</p>
            <p>（13）侵犯任何人的隐私权、名誉权、肖像权、专利权、商标权、著作权、商业秘密或其他专属权利的内容；</p>
            <p>（14）传销、直销、广告（寻求合作）、非法集资等内容，不限于文字、图片、二维码、站外链接等形式；</p>
            <p>
              （15）一个岗位发布多个职位信息,职位名称、职位描述、职位类型不明确或不符,职位名称包含职位名称以外的信息,不真实的企业介绍、招聘人身份、职位名称及描述等，以及其他存在较大风险的职位信息包括但不限于无经验交易员、无经验操盘手、无经验分析师、夜场等娱乐会所、网约车司机、微商代理、淘宝刷单、游戏代练、手机兼职、手工兼职、船员、影视娱乐圈等相关职位信息；
            </p>
            <p>（16）含有其他违法违规、违背善良风俗内容的。</p>
          </div>
          <div className="item">
            <p>5、用户在使用内推圈网络存储平台及享受内推圈提供的服务同时应做到：</p>
            <p>（1）遵守所有与内推圈服务有关的网络协议、规定和程序；</p>
            <p>（2）不得为任何非法目的而使用网络服务系统；</p>
            <p>（3）不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；</p>
            <p>（4）不得利用内推圈服务系统进行未经内推圈授权的广告；</p>
            <p>（5）与内推圈上公布信息的个人或公司沟通，保证在内推圈平台上进行；</p>
            <p>（6）用户接受以其注册的电子邮件地址接受内推圈发送的邮件或其他邮件资料，若希望"退订"这些邮件资料，可点击电子邮件中的退订链接退订，也可向内推圈邮箱abuse@liepin.com申请取消订阅；</p>
            <p>（7）用户不得通过任何技术手段侵入内推圈数据库，进行查阅、直接下载或者转发等功能操作，一经发现内推圈有权停止该用户账户的所有功能，并停止为该用户所有相关联账户提供任何服务；</p>
            <p>（8）用户有义务保证其在内推圈上发布的信息的真实性，不得夸张描述或编造虚假信息，一经发现，内推圈有权停止该用户账户的所有功能，同时停止与该账户所有相关联账户的全部功能；</p>
            <p>（9）用户不得利用内推圈发布任何新闻信息，包括但不限于有关政治、经济、军事、外交等社会公共事务的报道、评论，以及有关社会突发事件的报道、评论。</p>
          </div>
          <div className="item">
            <p>6、用户知晓并同意其拨打内推圈电话均可能会被录音，内推圈电话包括但不限于客服电话、销售电话等。</p>
          </div>
          <div className="item">
            <p>7、 内推圈有权拒绝为与本单位经营同类业务、有业务竞争关系或者其他利害关系的单位及个人提供服务。</p>
          </div>
          <div className="item">
            <p>8、用户理解并同意其线上资源均有有效期，有效期结束后线上资源自动清零，资源有效期不可中断或延期，内推圈其他网络服务条款或优惠活动另有规定的除外。</p>
          </div>
          <div className="item">
            <p>
              9、用户向内推圈提供资料（包括但不限于个人用户的真实的姓名、身份证号、从业信息、照片、目前工作的公司、邮箱、生物识别信息、住址、电话号码等；企业用户的企业名称、地址、联系方式、资质证照、企业账户管理人的个人信息以及企业账户管理人的在职证明等）后，内推圈对用户所提供的资料做本协议约定下的使用，用户对此知晓并予以认可。
            </p>
          </div>
          <div className="item">
            <p>10、用户理解并同意根据内推圈的页面提示完成认证后，可以使用招聘服务或为其他用户提供招聘服务。</p>
            <p>
              （1）个人用户的资料经企业用户中的诚猎通认证成功后，成为猎头。为保障猎头认证信息的持续真实有效，对已经通过认证的猎头，内推圈将视情况通过定期或不定期核查的方式，验证认证信息的真实有效性。如在核查过程中发现猎头提供的信息不符合规定或虚假或无效的，内推圈将依据具体情况采取包括但不限于扣除资源、限制登录、封停账号、解除合作等平台管制措施；
            </p>
            <p>（2）个人用户经企业用户认证后，成为该企业用户的企业HR；</p>
            <p>（3）其他个人用户完成招聘服务所需的认证后，也可使用招聘服务；</p>
            <p>（4）企业用户在内推圈完成相关认证后，可使用、购买内推圈提供的服务或为其他用户提供招聘服务。</p>
          </div>
          <div className="item">
            <p>11、用户如需注销内推圈账号，请联系内推圈客服微信itneituiquan03。</p>
          </div>
        </section>

        <div className="text12b">第三条 用户违规行为的处理</div>
        <section>
          <div className="item">
            <p>
              1、用户如违反使用规则、相关法律法规的，内推圈有权要求用户改正或直接采取一切必要的措施以减轻用户不当行为而造成的影响。其中，必要的措施包括但不限于删除用户张贴的内容、暂停或终止用户使用内推圈服务的权利。被终止使用内推圈服务的用户已缴纳的费用不予以退还，若其账户中存在金币、猎币等资源和用户等级的，则同步清零，资源、人选信息等不予退还。
            </p>
          </div>
          <div className="item">
            <p>2、具有猎头身份的用户还需遵守内推圈公布的猎头的行为规范和处罚规则，包括但不限于《信用分规则》。</p>
          </div>
          <div className="item">
            <p>3、如用户因违反使用规则或相关法律法规等造成内推圈被第三方索赔的，用户应全额补偿内推圈的一切费用(包括但不限于各种赔偿费、罚款、诉讼代理费、公证费及为此支出的其它合理费用)。</p>
          </div>
        </section>

        <div className="text12b">第四条 信息使用与隐私保护</div>
        <section>
          <div className="item">
            <p>
              1、内推圈及其授权合作机构承诺严格遵守国家法律法规，并仅可基于服务于业务之必要目的查询、收集用户信息。除非根据法律规定或有权机关要求，不得向任何人提供、泄露或出售。内推圈将最大努力保护用户的隐私。
            </p>
          </div>
          <div className="item">
            <p>
              2、为保障用户的信息安全，内推圈推行安全技术及配套的管理体系来尽量降低您的信息被泄露和更改的风险。例如：通过网络安全层软件（SSL）进行加密传输、信息加密存储、严格限制数据中心的访问。传输和存储个人敏感信息时，内推圈将采用加密、权限控制、去标识化等安全措施。
            </p>
          </div>
          <div className="item">
            <p>
              3、用户同意并授权内推圈在办理业务过程中（业务申请及业务存续期间）可以通过内推圈合作的查询服务提供机构、第三方验证机构向中国人民银行征信中心、国政通、安融惠众、阿里芝麻信用等第三方征信机构以及其他数据源查询并获取用户的相关信息。用户同意并授权内推圈合作的查询服务提供机构、第三方验证机构可将其合法获取的信息经过整合、加工并转化为信息评分或信息报告回传给内推圈，对用户提交的信息进行识别和风控分析，并向内推圈出具用户的信息认证结果、资信状况和验证信息。用户同意并授权内推圈与第三方合作机构使用相关信息。用户对内推圈的该等授权是不可撤销的。个人用户的信息包括但不限于本人的姓名、身份证号、照片、学历信息、职业和居住地址等个人基本信息，本人在个人贷款、各类信用卡和对外担保等信用活动中形成的交易记录等个人信贷交易信息，个人结算账户、个人住房公积金、个人养老保险金（缴存）、个人电信缴费、是否有犯罪的记录等非银行信用信息。企业用户的信息包括但不限于企业名称、地址、联系方式、资质证照、资信状况、企业账户管理人的个人信息等。<b>【用户已经充分理解并知晓该等信息被提供和使用的风险，包括但不限于：上述信息对用户的信用评价、以及分析报告等结果产生不利影响的风险；该等信息被查询服务提供机构依法提供给权威数据源和公司后可能被第三方不当利用的风险；以及基于用户的特定信用状况可能被不良信息骚扰的风险等】</b>，在此内推圈已向用户特别提示与说明。
            </p>
          </div>
          <div className="item">
            <p>
              4、内推圈将严格遵守隐私政策及其更新所载明的内容，遵循“合法、正当、必要”的原则来使用用户信息。用户同意将其个人信息使用于如下目的，以下没有明示的，内推圈依照本协议的其他约定，在协议约定的范畴下使用用户信息：
            </p>
            <p>（1）用户信息将被用于确保产品和服务的功能和安全、与用户进行交流、防止并追究欺诈或其他不当使用的情形；</p>
            <p>（2）用户信息将被用于产品和服务开发，尽管一般情况下，内推圈为此目的仅使用综合信息和统计性信息；</p>
            <p>（3）用户信息将被用于进行产品的个性化设计，并向用户提供更优化的服务，例如：向用户推荐更精准的职位或候选人，展示专门为其打造的内容和广告，或者用于调研；</p>
            <p>（4）为了更好的为用户提供服务，内推圈可能将用户同意公开的姓名、简历信息、个人标签、职位等信息推送给适合的招聘或求职用户；</p>
            <p>（5）如果用户参与内推圈举办的调查、抽奖、竞赛或类似推广活动，内推圈会将用户提供的信息用于管理此类活动；</p>
            <p>（6）为了更好的为用户提供服务，内推圈在经得用户同意后收集其手机通讯录信息，并用于向用户推荐可能感兴趣的人；</p>
            <p>（7）内推圈向用户的电子邮件、手机、通信地址等发送商业信息，包括不限于产品信息、优惠信息等。</p>
          </div>
          <div className="item">
            <p>5、在不透露单个用户隐私资料的前提下，内推圈有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>
          </div>
          <div className="item">
            <p>6、用户同意并授权内推圈对非个人信息的使用：</p>
            <p>（1）收集无法与任何特定个人直接建立联系的数据，诸如硬件配置、位置信息、软件操作信息、IP地址等，可以帮助内推圈更好地了解用户行为，改进产品、服务和广告宣传；</p>
            <p>（2）通过使用cookies和其他技术收集到的信息，可以带来更好的用户体验，并提高内推圈的总体服务品质。</p>
          </div>
          <div className="item">
            <p>7、用户同意，内推圈出于提升用户体验的角度和提供更好服务的目的，与内推圈的关联公司、合作信息查询机构以及其他第三方合作机构共享用户信息，包括但不限于以下情形：</p>
            <p>（1）与内推圈其他产品或关联公司产品共享：为更好的向用户提供服务，推荐用户可能感兴趣的信息，用户的部分个人信息可能会共享给内推圈其他产品或者关联公司的产品。内推圈的其他产品以及关联公司产品包括但不限于：内推圈同道、
              内推圈通App、内推圈校园、内推圈猎头版、内推圈通、校猎通等；</p>
            <p>
              （2）内推圈的某些服务将由内推圈的合作机构提供，出于提供内推圈的服务、履行本协议中的义务或行使相应的权利，内推圈可能会与合作的第三方（例如投资机构、招聘企业、人力资源服务机构及其他合作机构等）开展联合营销及其他活动，并为用户量身定制匹配相关信息。内推圈仅会出于合法、正当、必要、特定、明确的目的共享用户的信息，并且只会共享提供服务所必要的信息，以提供更好的客户服务和用户体验。同时，合作机构必须遵守内推圈的数据隐私和安全要求，并且无权将共享的个人信息用于与产品或服务无关的其他用途；
            </p>
            <p>（3）为了快速匹配用户的需求，内推圈可能会与第三方机构合作，与第三方共享用户的信息，以提升用户需求被满足的几率。</p>
          </div>
          <div className="item">
            <p>
              8、在内推圈服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到信息转让，内推圈会要求新的持有用户信息的公司、组织继续受本政策的约束，否则内推圈将要求该公司、组织和个人重新向用户征求授权同意。
            </p>
          </div>
          <div className="item">
            <p>
              9、内推圈特别声明，上述信息共享及使用场景下，内推圈依然会评估该第三方收集信息的合法性、正当性、必要性，并且严格遵守相关法律法规与监管要求。如果超出上述使用目的及使用场景的共享或使用，内推圈会再次征求用户的授权同意。
            </p>
          </div>
          <div className="item">
            <p>
              10、尤其注意：以下情形中，共享、转让、公开披露用户的信息无需事先征得用户的授权同意：
            </p>
            <p>（1）依照法律、法规、法院命令、监管机构命令的要求，或根据政府行为、监管要求或请求；</p>
            <p>（2）为执行相关服务协议或本政策、维护社会公共利益，为保护使用者、内推圈的客户、内推圈或内推圈的关联公司、其他用户或雇员的人身财产安全或其他合法权益合理且必要的用途；</p>
            <p>（3）内推圈有理由确认为遵守相关法律法规或配合政府履行监管义务所需时。</p>
          </div>
        </section>

        <div className="text12b">第五条 数据的保留和删除</div>
        <section>
          <div className="item">
            <p>1、为了保证网络操作的安全性，降低风险，用户应妥善保管好账户及密码。在用户使用相关服务时，一旦发现账户及密码已泄露或者存在泄露风险的，应及时与内推圈联系，以便内推圈及时采取相应措施以避免或降低相关损失。</p>
          </div>
          <div className="item">
            <p>2、用户发现内推圈违反法律、行政法规的规定或者双方的约定收集、使用用户个人信息的，有权要求内推圈删除其信息；发现内推圈收集、存储的其信息有错误的，有权要求内推圈予以更正，内推圈应当采取措施予以删除或者更正。</p>
          </div>
          <div className="item">
            <p>3、如果用户不再希望内推圈使用其信息来为提供内推圈服务，用户可以联系内推圈客服要求内推圈删除自己的信息并注销内推圈账户。请注意，如果用户申请删除其信息：</p>
            <p>（1）内推圈可能会保留用户的一些个人信息，以符合内推圈的合法商业利益，如欺诈侦测和预防以及提高安全性；</p>
            <p>（2）为了遵守内推圈的法律义务，内推圈可能会保留并使用用户的个人信息，例如，内推圈可能会为税务、法律报告及审计义务之目的保留用户的一些信息；</p>
            <p>（3）即使用户内推圈账户被注销，用户与他人分享的信息仍可能公开显示，此外，用户的信息的某些副本（如登录记录）可能会保留在内推圈的数据库中；</p>
            <p>（4）由于内推圈会维护所提供的服务以防止意外或恶意的损失和破坏， 用户的个人信息的残留副本可能不会在有限的时间内从用户的备份系统中删除。</p>
          </div>
          <div className="item">
            <p>4、撤销同意和处理限制</p>
            <p>（1）当用户删除自己的信息或者注销内推圈账户后，内推圈不再向用户提供之前注册账户及授权对应的服务，但并不影响之前基于用户授权而进行的个人信息处理；</p>
            <p>（2）在用户访问、修改和删除相关信息时，内推圈可能会要求用户进行身份验证，以保障账号的安全。同时，由于技术所限、法律或监管要求，内推圈可能无法满足用户的所有要求，但是内推圈会在合理的期限内答复请求。</p>
          </div>
        </section>

        <div className="text12b">第六条 版权声明</div>
        <section>
          <div className="item">
            <p>1、内推圈特有的标识、版面设计、编排方式等知识产权均属内推圈享有，未经内推圈许可，不得任意复制或转载。</p>
          </div>
          <div className="item">
            <p>
              2、内推圈用户发布的所有内容仅代表作者自己的立场和观点，与内推圈无关，由作者本人承担一切法律责任。
            </p>
          </div>
        </section>

        <div className="text12b">第七条 免责声明</div>
        <section>
          <div className="item">
            <p>1、用户理解内推圈无法保证网络服务任何条件下均不会中断，故内推圈对网络服务的及时性、安全性、准确性不作担保。同时，内推圈对服务器受病毒侵袭及其他故障侵扰免责。用户应做好数据备份，若发生使用本网站时的数据丢失的情况，内推圈免责。
            </p>
          </div>
          <div className="item">
            <p>
              2、内推圈不能保证某一招聘信息会有一定数量的用户来浏览，也不能保证会有一位特定的用户来浏览。内推圈并不保证所有信息、文本、图形、链接及其它项目的绝对准确性和完整性，故仅供用户参考使用。
            </p>
          </div>
          <div className="item">
            <p>
              3、用户承担通过内推圈登录到其他站点而形成的全部风险。同时了解通过内推圈之外与其他个人及用户的沟通不在内推圈控制范围内，其独立承担相应的后果。内推圈提供平台服务，故不担保用户发送给另一方用户的资料的真实性、精确性与可靠性。
            </p>
          </div>
          <div className="item">
            <p>
              4、内推圈提供平台服务，任何透过内推圈发布的招聘信息及其他产品或者服务均系用户自行发布，因其发布行为产生的法律后果由发布人承担；用户在通过内推圈得到资讯和信息后，与信息发布方所进行的任何交易均系其双方自主交易，双方若发生纠纷，皆与内推圈无关，内推圈不承担任何法律责任。
            </p>
          </div>
          <div className="item">
            <p>
              5、 因遇不可抗力造成的内推圈无法为用户提供服务的，内推圈不承担任何法律责任，不可抗力包括但不限于：政府行为、自然灾害、战争、罢工、电脑病毒、黑客攻击、电信管理部门因技术调整导致网络中断等。
            </p>
          </div>
        </section>

        <div className="text12b">第八条 通知、送达</div>
        <section>
          <div className="item">
            <p>1、内推圈对于用户所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。</p>
          </div>
          <div className="item">
            <p>2、 用户对于内推圈的通知应当通过内推圈如下正式公布的通信地址、电子邮件地址等联系信息进行送达。</p>
          </div>
          <div className="item">
            <p>3、内推圈的联系信息</p>
            <p>地址：成都市高新区菁蓉国际广场 </p>
            <p>电话：17781698372</p>
            <p>邮箱：longo.he@itneituiquan.com</p>
          </div>
        </section>

        <div className="text12b">第九条 法律适用、管辖与其他</div>
        <section>
          <div className="item">
            <p>
              1、本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
            </p>
          </div>
          <div className="item">
            <p>
              2、如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向内推圈所在地的人民法院提起诉讼。
            </p>
          </div>
          <div className="item">
            <p>
              3、本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
            </p>
          </div>
        </section>
      </div>
    )
  }
}
