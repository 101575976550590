import React, { Component } from 'react'
import { Steps, Form, Input, Select } from 'element-react'
import CustomButton from '../../components/CustomButton'
import SelectGender from '../../components/SelectGender'
import http from '../../api/http.js'
import api from '../../api/index.js'
import {isPhoneNum, isEmail, getQueryParams} from "../../utils";
import './index.scss'
import CustomToast from "../../components/CustomToast";


export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      queryParams: {},
      step: 0,
      passwordTipsShow: false,
      submitLoading: false,
      form: {
        mobile: "",
        name: "",
        gender: "MALE",
        nickname: "",
        email: "",
        jobGrade: "",
        payeeName: "",
        payeeAccount: "",
        payeeBank: "",
        goodDirection: "",
        goodDirectionArr: [],
        goodJob: "",
        goodJobArr: [],
      },
      rules: {
        mobile: [
          {
            required: true,
            message: '*必填项',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!isPhoneNum(value)) {
                callback(new Error('请输入正确的手机号'));
              } else {
                callback();
              }
            }
          }
        ],
        name: [
          {
            required: true,
            message: '*必填项',
            trigger: 'blur'
          },
        ],
        nickname: [
          {
            required: true,
            message: '*必填项',
            trigger: 'blur'
          },
        ],
        email: [
          {
            required: true,
            message: '*必填项',
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (!isEmail(value)) {
                callback(new Error('请输入正确的邮箱号'));
              } else {
                callback();
              }
            }
          }
        ],
        jobGrade: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('*必填项'));
              } else {
                callback();
              }
            }
          },
        ],
        // payeeName: [
        //   {
        //     required: true,
        //     message: '*必填项',
        //     trigger: 'blur'
        //   },
        // ],
        // payeeAccount: [
        //   {
        //     required: true,
        //     message: '*必填项',
        //     trigger: 'blur'
        //   },
        //   {
        //     validator: (rule, value, callback) => {
        //       if (!/^[0-9]*$/.test(value)) {
        //         callback(new Error('只能输入数字'));
        //       } else {
        //         callback();
        //       }
        //     }
        //   },
        // ],
        // payeeBank: [
        //   {
        //     required: true,
        //     message: '*必填项',
        //     trigger: 'blur'
        //   },
        // ],
        goodDirectionArr: [
          {
            required: true,
            message: '*必填项',
            trigger: 'blur,change',
            type: 'array'
          },
        ],
        goodJobArr: [
          {
            required: true,
            message: '*必填项',
            trigger: 'blur,change',
            type: 'array'
          },
        ],
      },
      dictionaries: {
        key163: [],// 职级
        key160: [],// 擅长职位
        key161: [],// 擅长业务
      },
      showBind: false,// 显示微信绑定
    }
  }

  componentDidMount() {
    this.state.queryParams = getQueryParams(this.props.location.search);
    this.getDictionaries();
    this.getUserData();
    if (this.state.queryParams.step === '2') {
      this.setState({
        step: 2
      })
    }
  }


  inputChange(key, value) {
    this.setState({
      form: Object.assign({}, this.state.form, {[key]: value })
    });
  }

  getDictionaries = () => {
    // 需要获取的字典
    let parentCodes = [163, 160, 161];
    parentCodes.forEach(value => {
      http.get(api.dictionaries(value))
        .then(res => {
          if (res.code === 200) {
            this.setState({
              dictionaries: {
                ...this.state.dictionaries,
                [`key${value}`]: res.data
              }
            })
          }
        })
    })
  }

  // 获取用户信息
  getUserData = () => {
    http.get(api.registerSelf)
      .then(res => {
        if (res.code === 200) {
          let data = res.data;
          let form = this.state.form;
          console.log(data)
          for (let key in data) {
            if (form[key] !== undefined) {
              form[key] = data[key]
            }
            if ((key === 'goodDirection' || key === 'goodJob') && data[key]) {
              form[`${key}Arr`] = data[key].split(',').map(value => Number(value))
            }
            if (!form.gender) {
              form.gender = 'MALE'
            }
          }
          this.setState({
            form:{
              ...this.state.form,
              name:''
            }
          })
        } else {
          CustomToast(res.message)
        }
      })
  }

  next = (step) => {
    if (step === 0) {
      // 完善个人资料
      this.refs.form.validate((valid) => {
        if (valid) {
          this.state.form.goodDirection = this.state.form.goodDirectionArr.toString();
          this.state.form.goodJob = this.state.form.goodJobArr.toString();
          this.setState({
            submitLoading: true
          });
          http.put(api.userFilling, this.state.form)
            .then(res => {
              if (res.code === 200) {
                this.setState({
                  step: 1,
                  showBind: true
                })
              } else {
                CustomToast(res.message)
              }
              this.setState({
                submitLoading: false
              });
            })
        }
      });
    } else if (step === 1) {
      this.setState({
        showBind: true
      })
    } else if (step === 2) {
      window.location.hash = '#/user-audit'
    }
  }

  closeBind = () => {
    console.log(111)
    this.setState({
      showBind: false
    })
  }


  render() {
    return (
      <div className="supplement-user-info">
        <h5 className="page-title">完善个人信息</h5>
        <div className="edit-step">
          <Steps space={178} active={this.state.step} finishStatus="success">
            <Steps.Step title="填写基本信息"/>
            <Steps.Step title="绑定微信"/>
            <Steps.Step title="　完成"/>
          </Steps>
        </div>
        {/*第一步 验证身份*/}
        {
          this.state.step === 0 && (
            <div className="form-box-wrap">
              <Form labelPosition="right" labelWidth="120" model={this.state.form} ref="form" rules={this.state.rules}>
                <Form.Item label="姓名" prop="name">
                  <Input value={this.state.form.name} onChange={this.inputChange.bind(this, 'name')} placeholder="请输入"/>
                </Form.Item>
                <Form.Item label="手机号" prop="mobile">
                  <Input value={this.state.form.mobile} onChange={this.inputChange.bind(this, 'mobile')} maxLength={11} placeholder="请输入"/>
                </Form.Item>
                <Form.Item label="性别">
                  <SelectGender value={this.state.form.gender} onChange={this.inputChange.bind(this, 'gender')}/>
                </Form.Item>
                <Form.Item label="昵称" prop="nickname">
                  <Input value={this.state.form.nickname} onChange={this.inputChange.bind(this, 'nickname')} placeholder="请输入"/>
                </Form.Item>
                <Form.Item label="邮箱" prop="email">
                  <Input value={this.state.form.email} onChange={this.inputChange.bind(this, 'email')} placeholder="请输入"/>
                </Form.Item>
                <Form.Item label="职级" prop="jobGrade">
                  <Select value={this.state.form.jobGrade} placeholder="请选择" onChange={this.inputChange.bind(this, 'jobGrade')}>
                    {
                      this.state.dictionaries.key163.map(el => {
                        return <Select.Option key={el.code} label={el.name} value={el.code} />
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item label="收款人姓名" prop="payeeName">
                  <Input value={this.state.form.payeeName} onChange={this.inputChange.bind(this, 'payeeName')} placeholder="请输入"/>
                </Form.Item>
                <Form.Item label="收款账号" prop="payeeAccount">
                  <Input value={this.state.form.payeeAccount} onChange={this.inputChange.bind(this, 'payeeAccount')} placeholder="请输入"/>
                </Form.Item>
                <Form.Item label="开户银行" prop="payeeBank">
                  <Input value={this.state.form.payeeBank} onChange={this.inputChange.bind(this, 'payeeBank')} placeholder="请输入"/>
                </Form.Item>
                <Form.Item label="擅长业务方向" prop="goodDirectionArr">
                  <Select value={this.state.form.goodDirectionArr} multiple={true} placeholder="最多选三个" onChange={this.inputChange.bind(this, 'goodDirectionArr')}>
                    {
                      this.state.dictionaries.key161.map(el => {
                        return <Select.Option key={el.code} label={el.name} value={el.code} />
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item label="擅长职位" prop="goodJobArr">
                  <Select value={this.state.form.goodJobArr} multiple={true} placeholder="最多选三个" onChange={this.inputChange.bind(this, 'goodJobArr')}>
                    {
                      this.state.dictionaries.key160.map(el => {
                        return <Select.Option key={el.code} label={el.name} value={el.code} />
                      })
                    }
                  </Select>
                </Form.Item>
              </Form>
              <div className="button-wrap">
                <CustomButton onClick={this.next.bind(this, 0)} loading={this.state.submitLoading}>下一步</CustomButton>
              </div>
              <div className="explain">
                <p className="close-button iconfont">&#xe605;</p>
                <p className="explain-cell"><span>说明</span></p>
                <p className="explain-cell"><span>擅长行业：</span>为了高效率的给企业推荐候选人，高效率的成单，建议每位顾问都选择自己非常擅长的领域来做</p>
                <p className="explain-cell"><span>擅长职位：</span>为了高效率的给企业推荐候选人，高效率的成单，建议每位顾问都选择自己非常擅长的职位来做</p>
              </div>
            </div>
          )
        }
        {/*第二步 输入新密码*/}
        {
          this.state.step === 1 && (
            <div className="form-box-wrap">
              <Form labelPosition="right" labelWidth="100" model={this.state.form}>
                <Form.Item label="手机号">
                  <Input value={this.state.form.mobile} placeholder="请输入" disabled/>
                </Form.Item>
                <Form.Item label="姓名">
                  <Input value={this.state.form.name} placeholder="请输入" disabled/>
                </Form.Item>
                {/*<Form.Item label="身份证号码">*/}
                  {/*<Input value={this.state.form.type} onChange={this.inputChange.bind(this, 'type')} placeholder="请输入"></Input>*/}
                {/*</Form.Item>*/}

              </Form>
              <div className="button-wrap">
                <CustomButton onClick={this.next.bind(this, 1)}>绑定微信</CustomButton>
              </div>
              {
                this.state.showBind && (
                  <div className="real-name-wrap-bg">
                    <div className="real-name-wrap">
                      <div className="qrcode-wrap">
                        {/*<img src={require("../../images/login-bg02.png")} alt=""/>*/}
                        {/*<p className="qrcode-tips">请用微信扫一扫，完成在心实名认证</p>*/}
                        <iframe
                          src={`https://open.weixin.qq.com/connect/qrconnect?appid=wx5f07dfa04c78e126&redirect_uri=${encodeURIComponent(`${window.location.origin}/#/supplement-bindwechat`)}&response_type=code&scope=snsapi_login&state=STATE`}
                          frameBorder="0"></iframe>
                      </div>
                      <div className="close-button-wrap">
                        <p className="qrcode-close-button iconfont" onClick={this.closeBind}>&#xe605;</p>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
        {/*第三步 完成*/}
        {
          this.state.step === 2 && (
            <div className="form-box-wrap">
              <p className="form-done-tips">恭喜您，已经完成绑定</p>
              <p className="form-done-desc">您已完成实名认证，待管理员审核后您就可以接单啦～</p>
              <div className="button-wrap">
                <CustomButton onClick={this.next.bind(this, 2)}>查看个人信息</CustomButton>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}
