/**
 * Created by Jachin on 2019/4/11.
 */

/**
 * 按钮组件
 * @attribute {Boolean} disabled 禁用按钮
 * @attribute {Boolean} loading 按钮处于加载中，同时禁用
 * @attribute {String} type 按钮类型，有 primary、gray、plain，默认 primary
 * @attribute {String} size 按钮类型，有 small和默认，默认高 40，small高 34
 * @attribute {Number} width 按钮宽度，默认宽度120
 * @event onClick 按钮点击事件
 * */
import React, { Component } from 'react'
import './index.scss'

export default class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  onClick = () => {
    if(this.props.disabled || this.props.loading) {
      return false
    }
    if(this.props.onClick) {
      this.props.onClick()
    }
  }

  render () {
    return (
      <p className={`custom-button ${this.props.disabled ? 'disabled' : (this.props.type || 'primary')} ${this.props.size || ''} ${this.props.loading ? 'loading' : ''}`} style={{width: `${this.props.width}px`}} onClick={this.onClick}>
        {
          this.props.loading && <i className="el-icon-loading"/>
        }
        {this.props.children}
        </p>
    )
  }
}

