import React, { Component } from 'react'
import './index.scss'



export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }


  onClick = (ev) => {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick()
    }
  }

  render () {
    return (
      <div className={`login-button ${this.props.disabled ? 'disabled' : 'primary'}`} onClick={this.onClick}>
        {
          this.props.children
        }
      </div>
    )
  }
}
