import React, { Component } from 'react';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import PrivateRoute from './router/PrivateRoute'
import AppRouter from './router'
import Login from "./views/login";
import LoginRegisterProtocol from "./views/login/register-protocol";
import LoginWechat from "./views/login/wechat";
import ResetPassword from "./views/login/reset-password/";
import SupplementUserInfoTips from "./views/supplement-user-info-tips";
import SupplementUserInfo from "./views/supplement-user-info";
import SupplementBindwechat from "./views/supplement-bindwechat";
const Loading = function () {
  return <div>loading...</div>
}
const UserAudit = Loadable({
  loader: () => import('./views/user-center/user-audit'),
  loading: Loading,
});

class App extends Component {
  render() {
    return (
      <Router>
        <Route path="/login" exact component={Login}/>
        <Route path="/login/register-protocol" exact component={LoginRegisterProtocol}/>
        <Route path="/login/wechat" component={LoginWechat}/>
        <PrivateRoute path="/home" component={AppRouter}/>
        <Route path="/reset-password" component={ResetPassword}/>
        <PrivateRoute path="/supplement-user-info-tips" component={SupplementUserInfoTips}/>
        <PrivateRoute path="/supplement-user-info" component={SupplementUserInfo}/>
        <PrivateRoute path="/supplement-bindwechat" component={SupplementBindwechat}/>
        <PrivateRoute path="/user-audit" component={UserAudit}/>
        <Route path='/' exact render={()=> (
          <Redirect to="/home/index"/>
        )}/>
      </Router>
    );
  }
}

export default App;
