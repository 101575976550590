/**
 * Created by Jachin on 2019/4/11.
 */

/**
 * 职位列表 item 组件
 * */
import React, { Component } from 'react'
import './index.scss'
// import CustomToast from '../../components/CustomToast'
// import http from "../../api/http";
// import api from "../../api";
// import {Tooltip} from "element-react";


export default class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
      return (
        <div className="content">
           {/* {
            this.props.data.deliveryNotice && <div>
               <div className='content-title'> 公司整体推荐须知</div>
               <div className='content-body' dangerouslySetInnerHTML={{__html: this.props.data.deliveryNotice}} style={{width:'100%'}} />
            </div>
           }

            {
              this.props.data.interviewFlow && <div>
              <div className='content-title'> 公司统一面试流程</div>
              <div className='content-body' dangerouslySetInnerHTML={{__html: this.props.data.interviewFlow}} style={{width:'100%'}} />
              </div>
            } */}
             {this.props.data.deliveryNotice && <div>
              <div className='content-title' v-if> 公司整体推荐须知</div>
               <div className='content-body' dangerouslySetInnerHTML={{__html: this.props.data.deliveryNotice}} style={{width:'100%'}} />

             </div>

             }

             {

                this.props.data.notice &&<div>
               <div className='content-title'> 职位具体推荐须知</div>
               <div className='content-body' dangerouslySetInnerHTML={{__html: this.props.data.notice}} style={{width:'100%'}} />
               </div>

             }
             {
              this.props.data.interviewFlow && <div>
              <div className='content-title'> 公司统一面试流程</div>
               <div className='content-body' dangerouslySetInnerHTML={{__html: this.props.data.interviewFlow}} style={{width:'100%'}} />
               </div>
            }


            </div>
      )

  }
}

