/**
 * Created by Jachin on 2019/4/11.
 */
import React, { Component } from 'react'
import './index.scss'
import { Dropdown, MessageBox } from 'element-react'
import CustomToast from '../CustomToast'
import http from '../../api/http.js'
import api from '../../api/index.js'
import { setCookie } from '../../utils/index'


export default class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {
      defaultActive: '#/home/index',
      userData: {},// 用户资料
      searchForm: {
        keyword: ''
      },
      routePaths: {
        '#/home/index': null,
        '#/home/jobs': [
          '#/home/jobs/job-detail',
        ],
        '#/home/company':[
          '#/home/company/company-detail',
        ],
        '#/home/recommend/recommend-record': [
          '#/home/recommend/recommend-create'
        ],
        '#/home/resume/resume-list': [

        ],
        '#/home/user-center': null,
      }
    }
  }

  componentDidMount() {
    let hash = window.location.hash.split('?')[0];
    let routePaths = this.state.routePaths;
    for (let key in routePaths) {
      if (key === hash || (routePaths[key] && routePaths[key].indexOf(hash) !== -1)) {
        this.setState({
          defaultActive: key
        });
        break;
      }
    }
    this.getUserData()
  }

  // 表单字段更新
  inputChange(key, ev) {
    let value = ev.target.value;
    this.setState({
      searchForm: Object.assign({}, this.state.searchForm, {[key]: value })
    });
  }

  changeRouter = (path) => {
    this.setState({
      defaultActive: path
    });
    window.location.hash = path;
  }

  // 获取用户信息
  getUserData = () => {
    http.get(api.registerSelf)
      .then(res => {
        if (res.code === 200) {
          this.setState({
            userData: res.data,
          })
        }
      })
  }

  // 退出登录
  logout = (command) => {
    MessageBox.confirm('确定退出登录吗？', '提示').then(() => {
      setCookie('token', '', 0);
      http.delete(api.logout);
      setTimeout(() => {
        this.changeRouter('#/login');
      }, 300)
    }).catch()
  }

  inputKeyUp = (ev) => {
    if (ev.keyCode === 13) {
      if (!this.state.searchForm.keyword) {
        CustomToast('请输入搜索内容');
        return false
      }
      window.open(`#/home/resume/resume-list?keyword=${encodeURIComponent(this.state.searchForm.keyword)}`, '_blank')
    }
  }



  render () {
    return (
      <div className="nav-header">
        <div className="logo">
          <div className="img-wrap">
            <img className="img" src={require('../../images/logo.png')} alt=""/>
          </div>
          <p className="text">猎头系统</p>
        </div>
        <div className="header-center">
          <div className="nav-wrap">
            <div className={`nav-cell ${this.state.defaultActive === '#/home/index' ? 'active' : ''}`} onClick={this.changeRouter.bind(this, '#/home/index')}>
              <div className="text">首页</div>
            </div>
            <div className={`nav-cell ${this.state.defaultActive === '#/home/company' ? 'active' : ''}`} onClick={this.changeRouter.bind(this, '#/home/company')}>
              <div className="text">公司列表</div>
            </div>
            <div className={`nav-cell ${this.state.defaultActive === '#/home/jobs' ? 'active' : ''}`} onClick={this.changeRouter.bind(this, '#/home/jobs')}>
              <div className="text">职位</div>
            </div>
            <div className={`nav-cell ${this.state.defaultActive === '#/home/recommend/recommend-record' ? 'active' : ''}`} onClick={this.changeRouter.bind(this, '#/home/recommend/recommend-record')}>
              <div className="text">推荐记录</div>
            </div>
            <div className={`nav-cell ${this.state.defaultActive === '#/home/resume/resume-list' ? 'active' : ''}`} onClick={this.changeRouter.bind(this, '#/home/resume/resume-list')}>
              <div className="text">简历库</div>
            </div>

            <div className={`nav-cell ${this.state.defaultActive === '#/home/user-center' ? 'active' : ''}`} onClick={this.changeRouter.bind(this, '#/home/user-center')}>
              <div className="text">个人中心</div>
            </div>
          </div>
          {/*消息通知，暂时不做*/}
          {/*<div className="message-button">*/}
            {/*<Dropdown trigger="click" menuAlign="start" menu={(*/}
              {/*<Dropdown.Menu>*/}
                {/*<div className="arrow"></div>*/}
                {/*<div className="message-list">*/}
                  {/*<p className="list">您推荐的人才有新的更新记录</p>*/}
                  {/*<p className="list">您推荐的人才有新的更新记录您推荐的人才有新的更新记录</p>*/}
                  {/*<p className="list">您推荐的人才有新的更新记录您推荐的人才有新的更新记录</p>*/}
                  {/*<p className="list">您推荐的人才有新的更新记录您推荐的人才有新的更新记录</p>*/}
                  {/*<p className="list">您推荐的人才有新的更新记录您推荐的人才有新的更新记录</p>*/}
                  {/*<p className="list">您推荐的人才有新的更新记录您推荐的人才有新的更新记录</p>*/}
                  {/*<p className="list">您推荐的人才有新的更新记录您推荐的人才有新的更新记录</p>*/}
                  {/*<p className="list">您推荐的人才有新的更新记录您推荐的人才有新的更新记录</p>*/}
                  {/*<p className="list">您推荐的人才有新的更新记录您推荐的人才有新的更新记录</p>*/}
                  {/*<p className="list">您推荐的人才有新的更新记录您推荐的人才有新的更新记录</p>*/}
                  {/*<p className="list">您推荐的人才有新的更新记录您推荐的人才有新的更新记录</p>*/}
                  {/*<p className="list">您推荐的人才有新的更新记录您推荐的人才有新的更新记录</p>*/}
                {/*</div>*/}
              {/*</Dropdown.Menu>*/}
            {/*)}>*/}
              {/*<div className="button">*/}
                {/*<p>消息通知</p>*/}
                {/*<p className="message-tips">99+</p>*/}
              {/*</div>*/}
            {/*</Dropdown>*/}
          {/*</div>*/}
          <div className="button-group">
            <div className="search-form">
              <i className="iconfont">&#xe609;</i>
              <input type="text" className="input" value={this.state.searchForm.keyword} onChange={this.inputChange.bind(this, 'keyword')} onKeyUp={this.inputKeyUp} placeholder="搜索简历库"/>
            </div>
            <button className="add-resume-button" onClick={this.changeRouter.bind(this, '#/home/resume/resume-upload')}>
              <i className="iconfont">&#xe6a7;</i>
              <p className="text">添加简历</p>
            </button>
          </div>
        </div>
        <div className="header-right">
          <div className="head-img">
            <img className="img" src={this.state.userData.avatarUrl || require('../../images/default-avatar.jpeg')} alt=""/>
          </div>
          <Dropdown trigger="click" menuAlign="start" onCommand={this.logout} menu={(
            <Dropdown.Menu>
              <Dropdown.Item command="logout">退出登录</Dropdown.Item>
            </Dropdown.Menu>
          )}>
            <div className="dropdown-link">
              {this.state.userData.name}<i className="el-icon-arrow-down"/>
            </div>
          </Dropdown>
        </div>
      </div>
    )
  }
}

