import React, { Component } from 'react'
import { Steps } from 'element-react'
import CustomButton from '../../../components/CustomButton'
import CustomToast from '../../../components/CustomToast'
import LoginInput from '../components/LoginInput'
import SMSButton from '../components/SMSButton'
import PasswordValidTips from '../components/PasswordValidTips'
import http from '../../../api/http.js'
import api from '../../../api/index.js'
import {isPhoneNum} from '../../../utils/index'
import './index.scss'

export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      steps: 0,
      captcha: '',
      passwordTipsShow: false,
      form: {// 表单
        mobile: "",
        captchaHashing: "",
        imageCaptcha: "",
        smsCaptcha: "",
        password: "",
        confirmPassword: "",
      },
      formRules: {// 表单验证规则
        mobile: {
          required: true,// 是否必填
          inputTips: '手机号码（11位数）',// 常规提示语
          errorTips: '',// 错误提示
        },
        imageCaptcha: {
          required: true,
          inputTips: '图形验证码',
          errorTips: '',
        },
        smsCaptcha: {
          required: true,
          inputTips: '短信验证码',
          errorTips: '',
        },
        password: {
          required: true,
          inputTips: '密码长度6~20位',
          errorTips: '',
        },
        confirmPassword: {
          required: true,
          inputTips: '重复密码',
          errorTips: '',
        },
      },
    }
  }

  componentDidMount () {
    this.createCaptcha()
  }

  // 表单字段更新
  inputChange(form, key, value) {
    this.setState({
      [form]: Object.assign({}, this.state[form], {[key]: value })
    });
  }

  // 组合验证码
  createCaptcha = () => {
    let id = +new Date();
    this.state.form.captchaHashing = id;
    this.setState({
      captcha: `${api.captcha}?id=${id}`
    })
  }

  // 密码修改时回调
  formPswChange = (key, value) => {
    if (value !== '' && value !== undefined) {
      this.setState({
        passwordTipsShow: true
      }, () => {
        let formRules = this.state.formRules;
        formRules.password.errorTips = this.passwordInpuitRef.onChange(value);
        this.setState({
          formRules
        })
      })
    } else {
      this.setState({
        passwordTipsShow: false
      })
    }
    this.inputChange('form', key, value)
  }

  // 密码输入框失去焦点回调
  passwordBlur = () => {
    this.setState({
      passwordTipsShow: false
    })
  }

  // 表单的ref
  formInputOnRef = (ref) => {
    if (!this.formInpuitRef) {
      this.formInpuitRef = []
    }
    this.formInpuitRef.push(ref);
  }

  // 表单密码输入框的ref
  passwordInputOnRef = (ref) => {
    this.passwordInpuitRef = ref;
  }

  // 清除错误提示
  clearErrorTips = (rules, key) => {
    let currentRules = this.state[rules];
    currentRules[key].errorTips = '';
    this.setState({
      [rules]: currentRules
    })
  }

  // 修改提交
  submit = () => {
    let pass = true;
    // 验证必填
    this.formInpuitRef.forEach(value => {
      if (!value.validRequire()) {
        pass = false;
      }
    });
    // 验证手机号
    if (this.state.form.mobile && !isPhoneNum(this.state.form.mobile)) {
      pass = false;
      let formRules = this.state.formRules;
      formRules.mobile.errorTips = '请输入正确的手机号';
      this.setState({
        formRules
      })
    }
    // 验证密码
    if (this.state.formRules.password.errorTips) {
      pass = false;
    }
    // 验证重复密码
    if (this.state.form.confirmPassword && this.state.form.confirmPassword !== this.state.form.password) {
      pass = false;
      let formRules = this.state.formRules;
      formRules.confirmPassword.errorTips = '两次密码不一致';
      this.setState({
        formRules
      })
    }
    if (!pass) {
      return false
    }

    // return
    http.put(api.forgetPassword, this.state.form)
      .then(res => {
        if (res.code === 200) {
          this.setState({
            steps: 2,
          })
        } else {
          CustomToast(res.message)
        }
      })
  }

  // 去登录
  toLogin = () => {
    window.location.hash = '#/login';
  }


  render() {
    return (
      <div className="reset-password">
        <h5 className="page-title">忘记密码</h5>
        <div className="edit-step">
          <Steps space={178} active={this.state.steps} finishStatus="success">
            {/*<Steps.Step title="验证身份"></Steps.Step>*/}
            <Steps.Step title="设置密码"></Steps.Step>
            <Steps.Step title="　完成"></Steps.Step>
          </Steps>
        </div>
        {/*第一步 输入新密码*/}
        {
          this.state.steps === 0 && (
            <div className="form-box-wrap">
              <div className="form-box-cell">
                <LoginInput
                  placeholder="请输入手机号"
                  icon="&#xe715;"
                  tips={this.state.formRules.mobile.inputTips}
                  errorTips={this.state.formRules.mobile.errorTips}
                  clearError={this.clearErrorTips.bind(this, 'formRules', 'mobile')}
                  require={true}
                  onRef={this.formInputOnRef}
                  maxLength={11}
                  value={this.state.form.mobile}
                  onChange={this.inputChange.bind(this, 'form', 'mobile')}/>
              </div>
              <div className="form-box-cell">
                <LoginInput
                  placeholder="请输入图形验证码"
                  icon="&#xe6a2;"
                  tips={this.state.formRules.imageCaptcha.inputTips}
                  errorTips={this.state.formRules.imageCaptcha.errorTips}
                  clearError={this.clearErrorTips.bind(this, 'formRules', 'imageCaptcha')}
                  require={true}
                  onRef={this.formInputOnRef}
                  maxLength={4}
                  value={this.state.form.imageCaptcha}
                  onChange={this.inputChange.bind(this, 'form', 'imageCaptcha')}/>
                <div className="captcha-wrap">
                  <img src={this.state.captcha} alt="" className="captcha" onClick={this.createCaptcha}/>
                </div>
              </div>
              <div className="form-box-cell">
                <LoginInput
                  placeholder="请输入短信验证码"
                  icon="&#xe62c;"
                  tips={this.state.formRules.smsCaptcha.inputTips}
                  errorTips={this.state.formRules.smsCaptcha.errorTips}
                  clearError={this.clearErrorTips.bind(this, 'formRules', 'smsCaptcha')}
                  require={true}
                  onRef={this.formInputOnRef}
                  maxLength={6}
                  value={this.state.form.smsCaptcha}
                  onChange={this.inputChange.bind(this, 'form', 'smsCaptcha')}/>
                <SMSButton mobile={this.state.form.mobile}/>
              </div>
              <div className="form-box-cell">
                <LoginInput
                  placeholder="新的登录密码"
                  icon="&#xe63a;"
                  tips={this.state.formRules.password.inputTips}
                  errorTips={this.state.formRules.password.errorTips}
                  clearError={this.clearErrorTips.bind(this, 'formRules', 'password')}
                  require={true}
                  onRef={this.formInputOnRef}
                  maxLength={20}
                  type="password"
                  value={this.state.form.password}
                  onChange={this.formPswChange.bind(this, 'password')}
                  onBlur={this.passwordBlur}/>
                {
                  this.state.passwordTipsShow && <PasswordValidTips onRef={this.passwordInputOnRef}/>
                }
              </div>
              <div className="form-box-cell">
                <LoginInput
                  placeholder="确认登录密码"
                  icon="&#xe63a;"
                  tips={this.state.formRules.confirmPassword.inputTips}
                  errorTips={this.state.formRules.confirmPassword.errorTips}
                  clearError={this.clearErrorTips.bind(this, 'formRules', 'confirmPassword')}
                  require={true}
                  onRef={this.formInputOnRef}
                  maxLength={20}
                  type="password"
                  value={this.state.form.confirmPassword}
                  onChange={this.inputChange.bind(this, 'form', 'confirmPassword')}/>
              </div>
              <div className="button-wrap">
                <CustomButton onClick={this.submit}>下一步</CustomButton>
              </div>
            </div>
          )
        }
        {/*第二步 完成 这里的steps值为2是为了样式显示需要，最后一个图标显示为完成状态*/}
        {
          this.state.steps === 2 && (
            <div className="form-box-wrap">
              <p className="form-done-tips">密码修改成功！</p>
              <div className="button-wrap">
                <CustomButton onClick={this.toLogin}>去登录</CustomButton>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}
