import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './css/style.scss'
import 'element-theme-default';
import './extend/index'


ReactDOM.render(<App />, document.getElementById('root'));

document.addEventListener("DOMContentLoaded", function () {

  const canv = document.createElement("canvas");
  canv.setAttribute("id", "watermarkCanvas")
  canv.setAttribute("style", "position: fixed;top:0; pointer-events: none;z-index:9999999;")
  document.body.append(canv)

  // 获取 canvas 元素
  var canvas = document.getElementById('watermarkCanvas');
  // 设置 canvas 的尺寸
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  // 获取渲染上下文
  var ctx = canvas.getContext('2d');

  // 设置文字样式
  ctx.font = '28px Arial';
  ctx.fillStyle = 'rgba(128, 128, 128, 0.1)';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';

  // 定义水印文字
  var watermarkText = '成都蓉易聘网络科技有限责任公司';

  // 计算水印的宽度和高度
  var textWidth = ctx.measureText(watermarkText).width;
  var textHeight = parseInt(ctx.font, 24); // 字体大小

  // 水印之间的间隔
  var spacingX = textWidth * 1;
  var spacingY = textHeight * 5;

  // 绘制水印
  for (var x = 0; x < canvas.width; x += spacingX) {
    for (var y = 0; y < canvas.height; y += spacingY) {
      ctx.save();
      ctx.translate(x + textWidth / 2, y + textHeight / 2);
      ctx.rotate(-Math.PI / 4); // 旋转 -45 度
      ctx.fillText(watermarkText, 0, 0);
      ctx.restore();
    }
  }
});
