/**
 * 登录或注册的输入框
 * @attribute {String} icon 输入框左边的图标，从 iconfont 取值
 * @attribute {String} errorTips 错误提示，输入框边框会变红
 * @attribute {String} tips 输入框提示，默认为常规提示，error 为 true 时可以作为错误提示
 * @attribute {Boolean} disabled 是否禁用
 * @method validRequire 验证必填，通过返回 true ，不通过返回 false
 * @event onBlur 输入框失去焦点回调
 * @event onChange 内容发生改变回调
 *
 * */


import React, { Component } from 'react'
import './index.scss'



export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,// 输入框是否处于激活或者有值的状态
      required: false,
    }
  }

  componentDidMount(){
    if (this.props.onRef) {
      this.props.onRef(this)
    }
  }

  onChange = (ev) => {
    this.props.onChange(ev.target.value)
  }

  onFocus = () => {
    this.setState({
      active: true,
      required: false
    })
    if (this.props.clearError) {
      this.props.clearError()
    }
  }

  onBlur = () => {
    if(this.props.value === '' || this.props.value === undefined) {
      this.setState({
        active: false
      })
    }
    if(this.props.onBlur) {
      this.props.onBlur()
    }
  }

  onKeyUp = (ev) => {
    if(this.props.value === '' || this.props.value === undefined) {
      return false
    }
    if(this.props.onKeyUp) {
      this.props.onKeyUp(ev)
    }
  }

  clearInput = () => {
    this.props.onChange('')
    this.setState({
      active: false
    })
  }

  // 验证必填
  validRequire = () => {
    if(this.props.require && (this.props.value === '' || this.props.value === undefined)) {
      this.setState({
        required: true
      })
      return false;
    }
    return true
  }

  activeFocus = () => {
    this.refs.currentInput.focus()
  }


  render () {
    return (
      <div className={`login-input-wrap ${this.state.active ? 'active' : ''} ${this.props.errorTips ? 'error' : ''} ${this.props.icon ? 'input-icon' : ''} ${this.state.required ? 'required' : ''} ${this.props.disabled ? 'disabled' : ''}`}>
        {
          this.state.active && <p className="login-input-tips">{this.props.errorTips || this.props.tips || ''}</p>
        }
        {
          this.props.icon && (
            <div className="login-input-icon">
              <i className="iconfont">{this.props.icon || ''}</i>
            </div>
          )
        }
        {
          this.state.required && (
            <p className="login-input-required" onClick={this.activeFocus}>（*必填项）</p>
          )
        }
        <input
          disabled={this.props.disabled}
          ref="currentInput"
          type={this.props.type || 'text'}
          className="login-input"
          value={this.props.value}
          placeholder={this.props.placeholder || ''}
          maxLength={this.props.maxLength}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyUp={this.onKeyUp}/>
        <div className="login-input-clear" onClick={this.clearInput}>
          <i className="iconfont">&#xe605;</i>
        </div>
      </div>
    )
  }
}
