/**
 * Created by Jachin on 2019/4/11.
 */
import React, { Component } from 'react'
import G2 from '@antv/g2'
import './index.scss'
import api from "../../api";
import http from "../../api/http";
import PositionDialog from '../../components/IndexPositionDialog/index'





export default class Index extends Component {

  constructor (props) {
    super(props)
    this.state = {
      chart1: null,
      chart2: null,
      userData: {},
      recommendData: [],// 推荐记录
      recommendTotalData: [],// 推荐统计
      totalType: 'day',// 统计类型，默认按天
      kpiData: [],// kpi
      positionHotData: [],// 推荐职位
    }
  }

  componentDidMount () {
    this.getUserData()
    this.getRecommendData()
    this.getRecommendTotalData()
    this.getKpiData()
    this.getPositionHot()
  }

  // 获取用户信息
  getUserData = () => {
    http.get(api.registerSelf)
      .then(res => {
        if (res.code === 200) {
          this.setState({
            userData: res.data,
          })
        }
      })
  }

  // 获取推荐记录
  getRecommendData = () => {
    http.get(api.indexRecommend)
      .then(res => {
        if (res.code === 200) {
          this.setState({
            recommendData: res.data,
          })
        }
      })
  }

  // 获取推荐统计
  getRecommendTotalData = () => {
    http.get(api.positionRecommendTotal, {
      params: {
        mode: this.state.totalType
      }
    })
      .then(res => {
        if (res.code === 200) {
          this.setState({
            recommendTotalData: res.data,
          })
          this.initChart1(res.data)
        }
      })
  }

  // 切换统计类型
  changeTotalType = (type) => {
    this.setState({
      totalType: type
    }, this.getRecommendTotalData)
  }

  // 获取kpi
  getKpiData = () => {
    http.get(api.kpiTotal)
      .then(res => {
        if (res.code === 200) {
          this.setState({
            kpiData: res.data,
          })
          this.initChart2(res.data)
        }
      })
  }

  // 获取kpi
  getPositionHot = () => {
    http.get(api.positionHotIndex)
      .then(res => {
        if (res.code === 200) {
          this.setState({
            positionHotData: res.data,
          })
        }
      })
  }

  // 过滤时间
  filterDate = (date) => {
    return new Date(date).Format('MM/dd')
  }

  // 初始化折线图图表
  initChart1 = (data) => {
    data = data.map(value => {
      value.date = this.filterDate(value.date);
      return value;
    });
    if(!this.state.chart) {
      this.state.chart = new G2.Chart({
        container: 'chart1',
        forceFit: true,
        width: 300,
        height: 300
      });
    }
    this.state.chart.source(data);
    this.state.chart.scale('count', {
      min: 0
    });
    this.state.chart.scale('date', {
      range: [0, 1]
    });
    this.state.chart.tooltip({
      crosshairs: {
        type: 'line'
      }
    });
    this.state.chart.line().position('date*count');
    this.state.chart.point().position('date*count').size(4).shape('circle').style({
      stroke: '#fff',
      lineWidth: 1
    });
    this.state.chart.render();
  }

  // 初始化环形图表
  initChart2 = (totalData) => {
    var data = [{
      type: '总推荐数量',
      value: totalData.total,
      color: '#0458f8',
      unit: '人',
    }, {
      type: '面试中数量',
      value: totalData.interviewer,
      color: '#1dbdb5',
      unit: '人',
    }, {
      type: 'Offer数量',
      value: totalData.offer,
      color: '#fa7197',
      unit: '人',
    }, {
      type: '到岗数量',
      value: totalData.arrive,
      color: '#9c26ed',
      unit: '人',
    }, {
      type: '过保数',
      value: totalData.ensure,
      color: '#00c790',
      unit: '人',
    }, {
      type: 'Offer率',
      value: Math.floor(totalData.offerRatio*100),
      color: '#006edf',
      unit: '%',
    }];
    var chart = new G2.Chart({
      container: 'chart2',
      forceFit: true,
      height: 290,
      padding: 'auto'
    });
    chart.source(data);
    chart.legend(false);
    chart.tooltip(false);
    chart.facet('rect', {
      fields: ['type'],
      padding: 20,
      showTitle: false,
      eachView: function eachView(view, facet) {
        var data = facet.data;
        if (data[0].type === 'Offer率') {
          data.push({
            type: '其他',
            value: 100 - data[0].value
          });
        } else {
          data.push({
            type: '其他',
            value: totalData.total - data[0].value
          });
        }
        view.source(data);
        view.coord('theta', {
          radius: 0.8,
          innerRadius: 0.9
        });
        view.intervalStack().position('value').color('type', [data[0].color, '#eceef1']).opacity(1);
        view.guide().html({
          position: ['50%', '50%'],
          html: `<div class="pie-chart-text-center"><p class="pie-chart-text-number">${data[0].value}<span class="pie-chart-text-unit">${data[0].unit}</span></p><p class="pie-chart-text-desc">${data[0].type}</p></div>`
        });
      }
    });
    chart.render();
  }

  // 前往推荐记录
  toRecommendRecord = () => {
    window.location.hash = '#/home/recommend/recommend-record'
  }

  // 前往推荐详情
  toRecommendDetail = (row) => {
    window.location.hash = `#/home/recommend/recommend-create?recommendType=3&positionId=${row.positionId}&resumeId=${row.resumeId}&deliveryId=${row.deliveryId}&resumeType=${row.resumeType}&resumeLibraryType=${row.resumeLibraryType}`
  }

  // 前往职位列表
  toJobsList = () => {
    window.location.hash = '#/home/jobs'
  }

  // 前往职位详情
  toPositionDetail = (id) => {
    window.open(`#/home/jobs/job-detail?id=${id}`, '_blank')
  }


  render () {
    return (
      <div className="page-index">
        <div className="block-top">
          <div className="cell-left">
            <div className="block-userinfo">
              <div className="block-header">
                <p className="block-header-title">个人资料</p>
              </div>
              <div className="user-info-wrap">
                <div className="user-avatar">
                  <img src={this.state.userData.avatarUrl || require('../../images/default-avatar.jpeg')} alt="" className="img"/>
                </div>
                <div className="user-info-right">
                  <p className="user-info-title">
                    {this.state.userData.greetingMessage}
                    <img src={require(`../../images/level${this.state.userData.headHunterLevelCode || 1}.png`)} alt="" className="tag-img"/>
                  </p>
                  <p className="user-info-mobile">手机号：{this.state.userData.mobile}</p>
                </div>
              </div>
            </div>
            <div className="block-recommend-record">
              <div className="block-header">
                <p className="block-header-title">推荐记录</p>
                <p className="block-header-single-button" onClick={this.toRecommendRecord}>查看全部</p>
              </div>

              <div className="block-recommend-record-list">
                {
                  this.state.recommendData.map(value => (
                    <div className="recommend-record-item single-ellipsis" key={value.deliveryId} >{value.createdTime}　{value.recommendedToRecord}</div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="cell-right">
            <div className="cell-right-inner">
              <div className="block-header">
                <p className="block-header-title">推荐数据统计</p>
                <p className="block-header-button-group">
                  <span className={`block-header-button--item${this.state.totalType === 'day' ? ' active' : ''}`} onClick={this.changeTotalType.bind(this, 'day')}>按日</span>
                  <span className={`block-header-button--item${this.state.totalType === 'week' ? ' active' : ''}`} onClick={this.changeTotalType.bind(this, 'week')}>按周</span>
                  <span className={`block-header-button--item${this.state.totalType === 'month' ? ' active' : ''}`} onClick={this.changeTotalType.bind(this, 'month')}>按月</span>
                </p>
              </div>

              <div className="chart1" id="chart1"/>
            </div>
          </div>
        </div>
        <div className="block-center">
          <div className="block-header">
            <p className="block-header-title">我的业绩</p>
            <p className="block-header-single-button" onClick={this.toRecommendRecord}>查看全部</p>
          </div>
          <div id="chart2"/>
        </div>
        <div className="block-bottom">
          <div className="block-header">
            <p className="block-header-title"><span className="block-header-title-tag">HOT</span>为你推荐的职位</p>
            <p className="block-header-single-button" onClick={this.toJobsList}>查看全部</p>
          </div>
          <div className="hot-jobs-wrap">
            {
              this.state.positionHotData.map(value => (
                <div className="hot-job-item" key={value.id} onClick={this.toPositionDetail.bind(this, value.id)}>
                  <p className="job-name">{value.name}</p>
                  <p className="job-commission">佣金：{value.expectCost}K</p>
                  <div className="job-desc multi-text-over">岗位要求：<p dangerouslySetInnerHTML={{__html: value.requirements}}></p></div>
                  <p className="job-tags">
                    {
                      value.labelList && value.labelList.map((item, index) => (
                        <span className="job-tag" key={index}>{item}</span>
                      ))
                    }
                  </p>
                </div>
              ))
            }
          </div>

        </div>
        <PositionDialog></PositionDialog>
      </div>
    )
  }
}

