import React, { Component } from 'react'
import CustomToast from '../../../components/CustomToast'
import http from '../../../api/http.js'
import api from '../../../api/index.js'
import {getQueryParams, setCookie} from "../../../utils";


export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      queryParams: {}
    }
  }

  componentDidMount() {
    this.state.queryParams = getQueryParams(this.props.location.search);
    this.bindWechat()
  }

  // 获取用户信息
  bindWechat = () => {
    http.get(api.wechatLogin, {
      params: {
        code: this.state.queryParams.code,
        state: 'STATE'
      }
    })
      .then(res => {
        if (res.code === 200) {
          setCookie('token', res.data.accessToken, 24);
          if (res.data.state === 'INITIALIZED') {
            window.location.hash = '#/supplement-user-info-tips'
          } else if (res.data.state === 'AUDITING') {
            window.location.hash = '#/user-audit';
          } else {
            window.location.hash = '#/home/index';
          }
        } else {
          CustomToast('用户未注册')
        }
      })
  }


  render() {
    return (
      <div/>
    )
  }
}
