/**
 * Created by Jachin on 2019/4/11.
 */
import React, { Component } from 'react'
import './index.scss'
import CustomToast from '../../components/CustomToast'

import { Tooltip, Table,Tabs,Pagination,Loading,Dialog,Button } from 'element-react'
import http from "../../api/http";
import api from "../../api";
import CollectPosition from '../CollectPositionDIalog'




export default class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchForm: {
        priorityHeadhunter:1, //优先级1-4
        pageIndex: 1,
        pageSize: 10,
      },

      data: [],
      pagination: {},
      pageLoading: false,// 页面加载中
      pageDialogVisible:true,  //首页弹窗
      columns: [
        {
          label: "公司名字",
          prop: "companyName",
          width: 200,
          align: 'center',
          render: (data) => {
            return (
              <Tooltip content={<div className="text-no-wrap">{data.companyName}</div>} placement="top">
                <Button type="text" className="show-overflow-tooltip text-left" onClick={this.toCompanyHome.bind(this, data.companyId)}>{data.companyName}</Button>
              </Tooltip>
            )
          }
        },
        {
          label: "职位名字",
          prop: "name",
          width: 120,
          align: 'center',
          render: (data) => {
            return (
              <Tooltip content={<div className="text-no-wrap">{data.name}</div>} placement="top">
                <Button type="text" className="show-overflow-tooltip text-left" onClick={this.toPositionDetail.bind(this, data.id)}>{data.name}</Button>
              </Tooltip>
            )
          }
        },
        {
          label: "学历要求",
          prop: "qualificationsName",
          width: 110,
          align: 'center',
        },
        {
          label: "薪水",
          prop: "salaryRangeName",
          width:200,
          align: 'center',
        },
        {
          label: "保证期",
          prop: "guaranteePeriod",
          width: 80,
          align: 'center',
          render: function (data) {
            return (<p>{data.guaranteePeriod}个月</p>)
          }
        },
        {
          label: "HC/人",
          prop: "workeNumbers",
          width: 100,
          align: 'center',
        },
        {
          label: "职位标签",
          prop: "headhunterPositionLabels",
          width: 250,
          align: 'center',
          render: (data) => {
            return (
              <div className='position'>
                {data.headhunterPositionLabels && data.headhunterPositionLabels.map(value => <p className={`position-tag ${value.labelCode == 1 ? 'position-urgentCall' : value.labelCode==2?'position-quick':''}`}>{value.labelName}</p> )}
                {data.companyLevelName  && (data.level==1 || data.level==2) && <p className='position-tag position-ka'>{data.companyLevelName}</p>}
                {data.newPosition  && <p className="position-tag position-new">新职位</p>}
              </div>
            )
          }
        },
        {
          label: "工作地",
          prop: "city",
          width: 80,
          align: 'center',
          render: function (data) {
            return (
              <Tooltip content={<div className="text-no-wrap">{data.city}</div>} placement="top">
                <p className="show-overflow-tooltip">{data.city}</p>
              </Tooltip>
            )
          }

        },

        {
          label: "发布时间",
          prop: "effectiveStart",
          width: 120,
          align: 'center',
          render: function (data) {
            return (
              <Tooltip content={<div className="text-no-wrap">{data.effectiveStart}</div>} placement="top">
                <p className="show-overflow-tooltip">{data.effectiveStart}</p>
              </Tooltip>
            )
          }
        },
        {
          label: "操作",
          prop: "handle",
          width: 100,
          align: 'center',
          render: (data) => {
            return (
              <div>
                <Button type="text" onClick={this.collectConfirm.bind(this, data)}>{data.collectionPosition ? '已接单' : '立即接单'}</Button>
              </div>
            )
          }
        }
      ],
      dialogVisible:false,  //接单取消职位弹窗
      dialogType:1,   //1-接单弹窗，2-取消接单弹窗
      dialogData:{},  //传递给弹框的数据
    }
  }

  componentDidMount () {
    this.search()
  }


  getData = (params, type) => {
    this.setState({
      pageLoading: true
    });
    http.get(api.positionList, {
      params
    })
      .then(res => {
        if (res.code === 200 ) {
          this.setState({
            data: res.data,
            pagination: res.pagination || {}
          }, () => {
            if (type === 'page') {
              this.scrollTop(300, 200)
            }
          })
        } else {
          CustomToast(res.message)
        }
        this.setState({
          pageLoading: false
        });
      })
  }

  // 根据条件查询
  search = (type) => {
    let data = {};
    let searchForm = this.state.searchForm;
    for (let key in searchForm) {
      if (searchForm[key]) {
        data[key] = searchForm[key];
      }
    }
    if (type !== 'page') {
      data.pageIndex = 1;
    }

    this.getData(data, type)

  }



  // 分页发生变更
  currentChange = (pageIndex) => {
    this.setState({
      searchForm: {
        ...this.state.searchForm,
        pageIndex
      }
    }, this.search.bind(this, 'page'))
  }


  //按优先级查询
  handlePriority = (type) => {
    this.setState({
      searchForm: {
        ...this.state.searchForm,
        priorityHeadhunter:type,
        pageIndex:1
      }
    }, () => {
      this.search()
    })
  }

    //点击节点取消接点按钮
    collectConfirm = async (data)=>{
      this.setState({
        dialogData:data,
        dialogVisible:true,
        dialogType: data.collectionPosition?2:1
      })
    }



  // 滚动到指定位置
  scrollTop = (number = 0, time) => {
    if (!time) {
      document.body.scrollTop = document.documentElement.scrollTop = number;
      return number;
    }
    const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
    let spacingInex = time / spacingTime; // 计算循环的次数
    let nowTop = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
    let everTop = (number - nowTop) / spacingInex; // 计算每次滑动的距离
    let scrollTimer = setInterval(() => {
      if (spacingInex > 0) {
        spacingInex--;
        this.scrollTop(nowTop += everTop);
      } else {
        clearInterval(scrollTimer); // 清除计时器
      }
    }, spacingTime);
  }

    // 前往公司首页
    toCompanyHome = (id) => {
      window.open(`#/home/company/company-detail?companyId=${id}`, '_blank');
    }

    // 前往职位详情
    toPositionDetail = (id) => {
      window.open(`#/home/jobs/job-detail?id=${id}`, '_blank');
    }

    //关闭弹窗的回调
    cancleDialog = async()=>{
      this.setState({
        dialogVisible: false
      })
    }

    //接单，取消接单成功的回调
    collectDealSuccess=async()=>{
      this.search();
    }



  render () {
    return (
      <div>

          <Dialog
                title="推荐职位列表"
                visible={ this.state.pageDialogVisible }
                onCancel={ () => this.setState({ pageDialogVisible: false}) }
                lockScroll={ false }
                size="100%"
              >
                <Dialog.Body>
                  <div className='dialog-notice'>
                  <Tabs activeName="1" onTabClick={ (tab) => this.handlePriority(tab.props.name) }>
                    <Tabs.Pane label="优先级-1" name="1"></Tabs.Pane>
                    <Tabs.Pane label="优先级-2" name="2"></Tabs.Pane>
                  </Tabs>
                  <Loading loading={this.state.pageLoading}>
                      <Table
                        style={{width: '100%'}}
                        columns={this.state.columns}
                        data={this.state.data}
                      />
                    </Loading>

                  </div>
                  <div className="block-pagination">
                    <Pagination layout="prev, pager, next, total, jumper" total={this.state.pagination.totalRow} pageSize={this.state.searchForm.pageSize} currentPage={this.state.pagination.pageIndex} onCurrentChange={this.currentChange}/>
                  </div>

                </Dialog.Body>
                <Dialog.Footer className="dialog-footer">
                  <Button type="primary" onClick={ () => this.setState({ pageDialogVisible: false}) }>关闭</Button>
                </Dialog.Footer>
          </Dialog>

          <CollectPosition
            data={this.state.dialogData}
            dialogVisible={this.state.dialogVisible}
            onCancel={this.cancleDialog}
            dialogType={this.state.dialogType}
            callback={this.collectDealSuccess}>
          </CollectPosition>



      </div>











      // <div className='page-jobs'>
      //   <CenterPanel>
      //     <div className="page-content">

      //       <div className="block-left">
      //         <Loading loading={this.state.pageLoading}>
      //           {
      //             this.state.data.map(value => <JobItem key={value.id} data={value} callback={this.JobCollectCb}/>)
      //           }
      //           {
      //             this.state.data.length === 0 && <div className="empty-tips">暂无数据</div>
      //           }
      //         </Loading>
      //         {
      //           this.state.data.length > 0 && (
      //             <div>

      //                <div className="block-pagination">
      //                 <Pagination layout="prev, pager, next, total, jumper" total={this.state.pagination.totalRow} pageSize={this.state.searchForm.pageSize} currentPage={this.state.pagination.pageIndex} onCurrentChange={this.currentChange}/>
      //               </div>

      //             </div>

      //           )
      //         }
      //       </div>

      //     </div>
      //   </CenterPanel>


      // </div>
    )
  }
}

