/**
 * Created by Jachin on 2019/7/21.
 */

/**
 * 右下角返回顶部和二维码组件
 * */

import React, { Component } from 'react'
import './index.scss'

export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showTopBar: false,// 返回顶部按钮开关
    }
  }

  componentDidMount() {
    // 挂载页面滚动监听
    window.addEventListener('scroll', this.bindScroll.bind(this))
  }
  componentWillUnmount() {
    // 移除页面滚动监听
    window.removeEventListener('scroll', this.bindScroll.bind(this));
  }

  bindScroll(event) {
    // 滚动的高度
    const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false) || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0);
    if (scrollTop > 300 && !this.state.showTopBar) {
      this.setState({
        showTopBar: true
      })
    }
    if (scrollTop < 300 && this.state.showTopBar) {
      this.setState({
        showTopBar: false
      })
    }
  }

  // 滚动到顶部
  scrollTop = (number = 0, time) => {
    if (!time) {
      document.body.scrollTop = document.documentElement.scrollTop = number;
      return number;
    }
    const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
    let spacingInex = time / spacingTime; // 计算循环的次数
    let nowTop = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
    let everTop = (number - nowTop) / spacingInex; // 计算每次滑动的距离
    let scrollTimer = setInterval(() => {
      if (spacingInex > 0) {
        spacingInex--;
        this.scrollTop(nowTop += everTop);
      } else {
        clearInterval(scrollTimer); // 清除计时器
      }
    }, spacingTime);
  }


  render() {
    return (
      <div className="footer-toolbar">
        <div className="footer-toolbar__qrcode-wrap">
          <div className="footer-toolbar__qrcode-icon">
            <i className="iconfont">&#xe608;</i>
          </div>
          <div className="footer-toolbar__qrcode">
            <img src={require('../../images/connect-qrcode.jpeg')} alt="" className="footer-toolbar__qrcode-img"/>
            <div className="footer-toolbar__qrcode-text">
              <p>扫码添加</p>
              <strong>组织者微信</strong>
            </div>
          </div>
        </div>
        {
          this.state.showTopBar && (
            <div className="footer-toolbar__to-top" onClick={this.scrollTop.bind(this, 0, 200)}>
              <i className="iconfont">&#xe64c;</i>
            </div>
          )
        }
      </div>
    )
  }
}
