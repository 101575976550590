import React, { Component } from 'react'
import './index.scss'
import http from '../../../../api/http.js'
import api from '../../../../api/index.js'
import CustomToast from '../../../../components/CustomToast'
import {isPhoneNum} from '../../../../utils'



export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: false,
      ss: 60
    }
  }

  onClick = (ev) => {
    if(this.state.disabled) {
      return false
    }
    if (!this.props.mobile) {
      CustomToast('请输入手机号')
      return false
    }
    if (!isPhoneNum(this.props.mobile)) {
      CustomToast('请输入正确的手机号');
      return false
    }
    this.setState({
      disabled: true
    });
    let postData = {
      mobile: this.props.mobile,
      smsTemplateType: 'LOGIN'
    }
    http.post(api.smsSend, postData)
      .then(res => {
      })
    this.countDown()
  }
  // 倒计时
  countDown = () => {
    this.timer = setInterval(() => {
      let ss = this.state.ss;
      ss--;
      if (ss <= 0) {
        ss = 60;
        clearInterval(this.timer);
        this.setState({
          disabled: false
        })
      }
      this.setState({
        ss
      })
    }, 1000)
  }

  render () {
    return (
      <div className={`sms-button ${this.state.disabled ? 'disabled' : 'primary'}`} onClick={this.onClick}>
        {
          this.state.disabled ? (
            `重新发送 ${this.state.ss}s`
            ) : (
            '获取验证码'
          )
        }
      </div>
    )
  }
}
